import Swiper, { Autoplay, EffectFade, Controller } from 'swiper';
import observeIntersection from './observeIntersection';

document.addEventListener('DOMContentLoaded', () => {
    const initHeroSlider = () => {
        // eslint-disable-next-line no-unused-vars
        const primary = new Swiper('#heroImageSlider_js', {
            loop: true,
            spaceBetween: 0,
            autoplay: {
                delay: 5000,
            },
            speed: 2500,
            slidesPerView: 1,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            modules: [Autoplay, EffectFade, Controller],
        });
        // eslint-disable-next-line no-unused-vars
        const secondary = new Swiper('#heroDescriptionSlider_js', {
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            modules: [Autoplay, EffectFade],
        });

        primary.controller.control = secondary;
    };

    if (document.body.classList.contains('home')) {
        observeIntersection('heroImageSlider_js', initHeroSlider, true);
    }
});
